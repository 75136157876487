import { TabletBackground, TvImage } from '@/assets/images/global';
import createSelectors from '@/store/selectors';
import { deepCopy } from '@/services/global/globalUtils';
import {
    type DeviceColorWithTvAndMediator,
    type DeviceStore,
} from '@/types/global/types';
import { create } from 'zustand';

export const INITIAL_DEVICE_STORE: DeviceStore = {
    deviceStatus: {
        red: {
            isConnected: false,
            isActivated: false,
            isReconnected: false,
            label: 'Tablette rouge',
            image: TabletBackground,
            indexMessage: 0,
        },
        blue: {
            isConnected: false,
            isActivated: false,
            isReconnected: false,
            label: 'Tablette bleue',
            image: TabletBackground,
            indexMessage: 0,
        },
        green: {
            isConnected: false,
            isActivated: false,
            isReconnected: false,
            label: 'Tablette verte',
            image: TabletBackground,
            indexMessage: 0,
        },
        orange: {
            isConnected: false,
            isActivated: false,
            isReconnected: false,
            label: 'Tablette orange',
            image: TabletBackground,
            indexMessage: 0,
        },
        tv: {
            isConnected: false,
            isActivated: true,
            isReconnected: false,
            label: 'TV',
            image: TvImage,
            indexMessage: 0,
        },
        mediator: {
            isConnected: false,
            isActivated: false,
            isReconnected: false,
            label: 'Tablette Médiateur',
            indexMessage: 0,
            image: TabletBackground,
        },
    },
    localDevice: '',
};

const useDeviceStoreBase = create<DeviceStore>(() =>
    deepCopy(INITIAL_DEVICE_STORE)
);

export const useDeviceStore = createSelectors(useDeviceStoreBase);

export const setDeviceConnectedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
): void => {
    useDeviceStoreBase.setState((state) => ({
        deviceStatus: {
            ...state.deviceStatus,
            [deviceColor]: {
                ...state.deviceStatus[deviceColor],
                isConnected: status,
            },
        },
    }));
};

export const setDeviceActivatedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
): void => {
    useDeviceStoreBase.setState((state) => ({
        deviceStatus: {
            ...state.deviceStatus,
            [deviceColor]: {
                ...state.deviceStatus[deviceColor],
                isActivated: status,
            },
        },
    }));
};

export const setLocalDevice = (device: string): void => {
    useDeviceStore.setState({ localDevice: device });
};

export const resetDevice = (): void => {
    useDeviceStore.setState(() => ({
        deviceStatus: deepCopy({
            ...INITIAL_DEVICE_STORE.deviceStatus,
            blue: { ...INITIAL_DEVICE_STORE.deviceStatus.blue, isConnected: true },
            red: { ...INITIAL_DEVICE_STORE.deviceStatus.red, isConnected: true },
            orange: {
                ...INITIAL_DEVICE_STORE.deviceStatus.orange,
                isConnected: true,
            },
            green: {
                ...INITIAL_DEVICE_STORE.deviceStatus.green,
                isConnected: true,
            },
            tv: { ...INITIAL_DEVICE_STORE.deviceStatus.tv, isConnected: true },
            mediator: {
                ...INITIAL_DEVICE_STORE.deviceStatus.mediator,
                isConnected: true,
            },
        }),
    }));
};
