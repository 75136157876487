import { GAMESTATUS } from '@/types/games/types';

import headerDatas from '@/services/datas/actionContent.json';
import { deepCopy } from '@/services/global/globalUtils';
import {
    type Game5Store,
    type Game5Action,
    type ResponseSituation,
} from '@/types/games/game5/types';
import { create } from 'zustand';
import createSelectors from '@/store/selectors';

interface ItemsSelected {
    item: ResponseSituation;
    itemKey: keyof Game5Action;
}

export const INITIAL_GAME5_STORE: Game5Store = {
    itemsSelected: {} as Game5Action,
    headerContent: headerDatas[GAMESTATUS.GAME5ACTION].tv.headerContent,
    currentIndex: 0,
};

const useGame5StoreBase = create<Game5Store>(() => deepCopy(INITIAL_GAME5_STORE));

export const useGame5Store = createSelectors(useGame5StoreBase);

export const selectItemsSelectedGame5 = (): Game5Action => {
    return useGame5StoreBase.getState().itemsSelected;
};

export const selectCurrentIndexGame5 = (): number => {
    return useGame5StoreBase.getState().currentIndex;
};

export const setItemsSelectedGame5 = (data: ItemsSelected): void => {
    useGame5StoreBase.setState((state) => ({
        itemsSelected: { ...state.itemsSelected, [data.itemKey]: data.item },
    }));
};

export const setCurrentSituation = (newIdx: number): void => {
    useGame5StoreBase.setState({ currentIndex: newIdx });
};

export const resetItemsSelectedGame5 = (): void => {
    useGame5StoreBase.setState({
        itemsSelected: deepCopy(INITIAL_GAME5_STORE.itemsSelected),
    });
};
