import { type Nullable } from '@/types/global/types';
import type {
    Emotion,
    EmotionSecondary,
    HeaderContent,
    Item,
    _Symbol,
} from '../types';

export interface Game1Store {
    itemsSelected: {
        action1: Nullable<GameOneAction1>;
        action2: Nullable<GameOneAction2>;
    };
    itemsFound: Item[];
    headerContent: HeaderContent;
}

export const INITIAL_GAME1_STORE: Game1Store = {
    itemsSelected: { action1: null, action2: null },
    itemsFound: Array(4).fill(null),
    headerContent: {} as HeaderContent,
};
export interface GameOneAction1 {
    emotion: Emotion;
    symbol: _Symbol;
}

export interface GameOneAction2 {
    emotion: Emotion;
    weakEmotion: EmotionSecondary;
    strongEmotion: EmotionSecondary;
}
