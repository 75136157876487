import ActionButton, {
    type ActionType,
} from '@/features/devices/tablets/components/actionButton/ActionButton';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';
import { useTranslation } from 'react-i18next';

import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';

import { useGame1Store } from '@/store/games/game1Store';
import { Device } from '@/services/global/types';

const OrangeGame1Action1: React.FC = () => {
    const currentOverlayDescriptions = useOverlayStore.use.descriptions();
    const overlayType = useOverlayStore.use.overlayType();
    const currentGameStatus = useGameStore.use.gameStatus();
    const gameResult = useGameStore.use.gameResult();
    const itemsSelected = useGame1Store.use.itemsSelected().action1;

    const deviceColor = Device.orange;
    const showCurrentOverlay = currentOverlayDescriptions.length > 0;
    const { t } = useTranslation(['global', 'actionContent']);

    return (
        <>
            {showCurrentOverlay ? (
                <TabletScreen
                    typeBackground="circle"
                    step="action"
                    deviceColor={deviceColor}
                >
                    <ActionButton
                        title={
                            overlayType === 'success'
                                ? t('buttons.next')
                                : t('buttons.retry')
                        }
                        action={`${overlayType}Validation` as ActionType}
                        type={overlayType}
                    />
                </TabletScreen>
            ) : (
                <TabletScreen
                    typeBackground="circle"
                    step="action"
                    deviceColor={deviceColor}
                >
                    {gameResult === '' && (
                        <>
                            {itemsSelected?.emotion && itemsSelected.symbol ? (
                                <ActionButton
                                    title={t('buttons.validate')}
                                    action="handleValidationGame1Action1"
                                    type="neutral"
                                />
                            ) : (
                                <p>
                                    {t(
                                        `${currentGameStatus}.${deviceColor}.bodyContent.empty`,
                                        { ns: 'actionContent' }
                                    )}
                                </p>
                            )}
                        </>
                    )}
                </TabletScreen>
            )}
        </>
    );
};

export default OrangeGame1Action1;
