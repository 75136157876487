import { Ambiance } from '@/assets/sounds/soundsEffect';
import Layout from '@/features/devices/tv/Layout';
import Game2Action from '@/features/devices/tv/components/games/game2/Game2Action';
import Game3Action from '@/features/devices/tv/components/games/game3/Game3Action';
import Game4Action from '@/features/devices/tv/components/games/game4/Game4Action';
import Game5Action from '@/features/devices/tv/components/games/game5/Game5Action';
import Overlay from '@/features/devices/tv/components/overlay/Overlay';
import WelcomeScreen from '@/features/devices/tv/components/welcome/WelcomeScreen';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import styles from '@/assets/styles/app.module.scss';
import { useEffect, useState } from 'react';
import { GAMESTATUS, type GameStatus } from '@/types/games/types';
import Game1Action1 from './components/games/game1/Game1Action1';
import Game1Action2 from './components/games/game1/Game1Action2';
import useReSynchronizeDevice from '@/services/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';

const Tv: React.FC = () => {
    const [isFirstRender, setIsFirstRender] = useState(true);

    const currentGameStatus = useGameStore.use.gameStatus();
    const currentOverlayDescriptions = useOverlayStore.use.descriptions();
    const overlayType = useOverlayStore.use.overlayType();

    const isCurrentOverlay = currentOverlayDescriptions.length > 0;

    const status = overlayType;

    useReSynchronizeDevice('tv');

    useEffect(() => {
        emitSocketEvent('send_synchronize_device', { deviceColor: 'tv' });
    }, []);

    useEffect(() => {
        document.title = '🖥️ TV';

        if (currentGameStatus === GAMESTATUS.WELCOME_SCREEN) return;
        if (!isFirstRender) return;

        const ambianceSound = new Audio(Ambiance);
        ambianceSound.loop = true;
        ambianceSound.volume = 0.2;
        ambianceSound.play();
        setIsFirstRender(false);
    }, [currentGameStatus, isFirstRender]);

    const getActionGameComponent = (gameStatus: GameStatus) => {
        switch (gameStatus) {
            case GAMESTATUS.GAME1ACTION1:
                return <Game1Action1 />;
            case GAMESTATUS.GAME1ACTION2:
                return <Game1Action2 />;
            case GAMESTATUS.GAME2ACTION:
                return <Game2Action />;
            case GAMESTATUS.GAME3ACTION:
                return <Game3Action />;
            case GAMESTATUS.GAME4ACTION:
                return <Game4Action />;
            case GAMESTATUS.GAME5ACTION:
                return <Game5Action />;
            default:
        }
    };

    const handleRender = () => {
        switch (currentGameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
                return <WelcomeScreen isWelcomeScreen />;
            case GAMESTATUS.INTRODUCTION:
            case GAMESTATUS.GAME1ACTION1INTRODUCTION:
            case GAMESTATUS.GAME1ACTION2INTRODUCTION:
            case GAMESTATUS.GAME2INTRODUCTION:
            case GAMESTATUS.GAME3INTRODUCTION:
            case GAMESTATUS.GAME4INTRODUCTION:
            case GAMESTATUS.GAME5INTRODUCTION:
            case GAMESTATUS.GAME1ACTION2PREVENTION:
            case GAMESTATUS.GAME2PREVENTION:
            case GAMESTATUS.GAME3PREVENTION:
            case GAMESTATUS.GAME4PREVENTION:
            case GAMESTATUS.GAME5PREVENTION:
                return <>{isCurrentOverlay && <Overlay type="neutral" />}</>;

            case GAMESTATUS.GAME1ACTION1:
            case GAMESTATUS.GAME1ACTION2:
            case GAMESTATUS.GAME2ACTION:
            case GAMESTATUS.GAME3ACTION:
            case GAMESTATUS.GAME4ACTION:
            case GAMESTATUS.GAME5ACTION:
                return (
                    <>
                        {isCurrentOverlay ? (
                            <Overlay type={overlayType} />
                        ) : (
                            <Layout>
                                {getActionGameComponent(currentGameStatus)}
                            </Layout>
                        )}
                    </>
                );

            case GAMESTATUS.GAME1UNLOCK:
            case GAMESTATUS.GAME2UNLOCK:
            case GAMESTATUS.GAME3UNLOCK:
            case GAMESTATUS.GAME4UNLOCK:
            case GAMESTATUS.GAME5UNLOCK:
                return <Overlay type="unlock" />;

            case GAMESTATUS.FINISHED:
                return <WelcomeScreen isWelcomeScreen={false} />;
        }
    };

    return <div className={styles[`${status}Container`]}>{handleRender()}</div>;
};

export default Tv;
