import headerDatas from '@/services/datas/actionContent.json';
import dataGlobal from '@/services/datas/global.json';

import { deepCopy } from '@/services/global/globalUtils';
import createSelectors from '@/store/selectors';
import { type Game2Action, type Game2Store } from '@/types/games/game2/types';
import {
    GAMESTATUS,
    type Emotion,
    type Item,
    type Stimulus,
} from '@/types/games/types';
import { create } from 'zustand';

export const INITIAL_GAME2_STORE: Game2Store = {
    isConfirmSelectionButton: false,
    itemsSelected: {
        emotion: {} as Emotion,
        work: {} as Stimulus,
        friends: {} as Stimulus,
        family: {} as Stimulus,
        sport: {} as Stimulus,
    },
    itemsFound: Array(dataGlobal.emotions.length).fill(null),
    headerContent: headerDatas[GAMESTATUS.GAME2ACTION].tv.headerContent,
};

const useGame2StoreBase = create(() => deepCopy(INITIAL_GAME2_STORE));

export const useGame2Store = createSelectors(useGame2StoreBase);

export const selectItemsSelectedGame2 = (): Game2Action => {
    return useGame2StoreBase.getState().itemsSelected;
};

export const selectItemsFoundGame2 = (): Item[] => {
    return useGame2StoreBase.getState().itemsFound;
};

export const selectIsConfirmSelectionButtonGame2 = (): boolean => {
    return useGame2StoreBase.getState().isConfirmSelectionButton;
};

export const setItemsSelectedGame2 = (action: {
    item: Stimulus | Emotion;
    itemKey: keyof Game2Action;
}): void => {
    const { item, itemKey } = action;
    useGame2StoreBase.setState((state) => ({
        itemsSelected: {
            ...state.itemsSelected,
            [itemKey]: item,
        },
    }));
};

export const setItemsFoundGame2 = (emotion: Emotion): void => {
    const newItemsFound = deepCopy(useGame2StoreBase.getState().itemsFound);
    newItemsFound.splice(emotion.id - 1, 1, emotion);

    useGame2StoreBase.setState({
        itemsFound: newItemsFound,
    });
};

export const setIsConfirmSelectionButton = (status: boolean): void => {
    useGame2StoreBase.setState({ isConfirmSelectionButton: status });
};

export const setNextEmotionGame2 = (emotion: Emotion): void => {
    useGame2StoreBase.setState((state) => ({
        itemsSelected: { ...state.itemsSelected, emotion },
    }));
};

export const setEmotionToItemsSelected = (): void => {
    const emotionFound = useGame2StoreBase
        .getState()
        .itemsFound.findLast((emotion) => emotion);

    const index = dataGlobal.emotions.findIndex(
        (emotion) => emotion.id === emotionFound?.id
    );

    const nextEmotion = dataGlobal.emotions[index + 1] as Emotion;

    useGame2StoreBase.setState({
        itemsSelected: {
            emotion: nextEmotion,
            work: {} as Stimulus,
            friends: {} as Stimulus,
            family: {} as Stimulus,
            sport: {} as Stimulus,
        } as Game2Action,
    });
};

export const resetItemsSelectedGame2 = (): void => {
    useGame2StoreBase.setState({
        itemsSelected: deepCopy(INITIAL_GAME2_STORE.itemsSelected),
        isConfirmSelectionButton: false,
    });
};

export const resetItemsFoundGame2 = (): void => {
    useGame2StoreBase.setState({
        itemsFound: deepCopy(INITIAL_GAME2_STORE.itemsFound),
        itemsSelected: deepCopy(INITIAL_GAME2_STORE.itemsSelected),
    });
};
