import { useCallback, useEffect, useRef, useState } from 'react';
import { useInterval } from '@/features/devices/tv/components/games/game3/hooks/useInterval';
import {
    BOARD_HEIGHT,
    hasCollisions,
    initializeEmptyBoard,
    useTetrisBoard,
} from '@/features/devices/tv/components/games/game3/hooks/useTetrisBoard';
import dataGlobal from '@/services/datas/global.json';
import {
    type Block,
    type BlockShape,
    type BoardShape,
    type CellOptions,
    EmptyCell,
    SHAPES,
} from '@/services/global/types';
import type { ItemsSelected } from '@/types/games/types';

// Enumeration for different tick speeds in the game loop
enum TickSpeed {
    Normal = 1400,
    Sliding = 100,
    Fast = 50,
}
interface TetrisHookReturn {
    board: BoardShape;
    startTetrisGame: (itemsSelected: ItemsSelected) => void;
    isPlaying: boolean;
    score: number;
    upcomingBlocks: Block[];
    handleClick: (typeMouse: string, action: string) => void;
    updateUpcomingBlocks: (selectedBlocks: Block[]) => void;
    tetrisFinish: boolean;
    tetrisAutoCompletion: (emotionId: number | undefined) => void;
}
const MAXTETRIS = 3;

// Custom hook for managing the state and logic of the Tetris game
export const useTetris = (): TetrisHookReturn => {
    const [score, setScore] = useState(0);
    const [upcomingBlocks, setUpcomingBlocks] = useState<Block[]>([]);
    const [isCommitting, setIsCommitting] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [tickSpeed, setTickSpeed] = useState<TickSpeed | null>(null);
    const [numberOfTetrisCommit, setNumberOfTetrisCommit] = useState(0);
    const [tetrisFinish, setTetrisFinish] = useState(false);
    const isPressingLeft = useRef(false);
    const isPressingRight = useRef(false);
    const moveIntervalID = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (isCommitting) {
            setNumberOfTetrisCommit((prevState) => prevState + 1);
        }
    }, [isCommitting]);

    // Destructuring state from useTetrisBoard hook
    const [
        { board, droppingRow, droppingColumn, droppingBlock, droppingShape },
        dispatchBoardState,
    ] = useTetrisBoard();

    // Helper function to create a block
    const createBlock = (tetris: string, itemsSelected: ItemsSelected) => {
        const newBlock = {
            tetris: itemsSelected[tetris as keyof ItemsSelected],
            character: tetris.replace('tetris', ''),
        };
        return newBlock;
    };

    // For mediator tablet auto complete tetris global shape
    const tetrisAutoCompletion = useCallback(
        (emotionId: number | undefined) => {
            setTetrisFinish(true);
            setIsPlaying(false);
            setIsCommitting(false);
            setTickSpeed(null);
            setNumberOfTetrisCommit(0);
            dispatchBoardState({
                type: 'tetris-auto-completed',
                emotionId: emotionId,
            });
            setUpcomingBlocks([]);
        },

        [dispatchBoardState]
    );

    // Refactored startTetrisGame function
    const startTetrisGame = useCallback(
        (itemsSelected: ItemsSelected) => {
            const allTetris = Object.keys(itemsSelected).filter((item) =>
                item.includes('tetris')
            );
            const currentEmotionId = itemsSelected?.emotion?.id;
            const currentGoodCombination =
                dataGlobal.GAME3ACTION.goodCombinations.find(
                    (combinaison) => combinaison.emotionId === currentEmotionId
                );

            const lastBlock = allTetris
                ?.filter(
                    (tetris) =>
                        tetris !== `tetris${currentGoodCombination?.firstBlock}` &&
                        tetris !== `tetris${currentGoodCombination?.secondBlock}`
                )
                .map((tetris) => createBlock(tetris, itemsSelected));

            const firstBlock = allTetris
                ?.filter(
                    (tetris) =>
                        tetris === `tetris${currentGoodCombination?.firstBlock}`
                )
                .map((tetris) => createBlock(tetris, itemsSelected));

            const secondBlock = allTetris
                ?.filter(
                    (tetris) =>
                        tetris === `tetris${currentGoodCombination?.secondBlock}`
                )
                .map((tetris) => createBlock(tetris, itemsSelected));

            const waitingBlocks = [lastBlock[0], secondBlock[0]];
            setScore(0);
            setUpcomingBlocks(waitingBlocks as Block[]);
            setIsCommitting(false);
            setIsPlaying(true);
            setTickSpeed(TickSpeed.Normal);
            dispatchBoardState({
                type: 'start',
                firstBlock: firstBlock[0] as Block,
            });
        },
        [dispatchBoardState]
    );

    // Function to start a new game
    const updateUpcomingBlocks = useCallback((startingBlocks: Block[]) => {
        setUpcomingBlocks(startingBlocks as Block[]);
        setTetrisFinish(false);
    }, []);

    const updateMovementInterval = useCallback(() => {
        if (moveIntervalID.current) clearInterval(moveIntervalID.current);
        dispatchBoardState({
            type: 'move',
            isPressingLeft: isPressingLeft.current,
            isPressingRight: isPressingRight.current,
        });
        moveIntervalID.current = setInterval(() => {
            dispatchBoardState({
                type: 'move',
                isPressingLeft: isPressingLeft.current,
                isPressingRight: isPressingRight.current,
            });
        }, 300);
    }, [dispatchBoardState, isPressingLeft, isPressingRight]);

    const handleKeyDown = useCallback(
        (action: string) => {
            if (action === 'down') {
                setTickSpeed(TickSpeed.Sliding);
            }

            if (action === 'up') {
                dispatchBoardState({
                    type: 'move',
                    isRotating: true,
                });
            }

            if (action === 'left') {
                isPressingLeft.current = true;
                updateMovementInterval();
            }

            if (action === 'right') {
                isPressingRight.current = true;
                updateMovementInterval();
            }
        },
        [dispatchBoardState, updateMovementInterval]
    );

    const handleKeyUp = useCallback(
        (action: string) => {
            if (action === 'down') {
                setTickSpeed(TickSpeed.Fast);
            }

            if (action === 'left') {
                isPressingLeft.current = false;
                updateMovementInterval();
            }

            if (action === 'right') {
                isPressingRight.current = false;
                updateMovementInterval();
            }
        },
        [updateMovementInterval]
    );

    const handleClick = useCallback(
        (typeMouse: string, action: string) => {
            if (typeMouse === 'up') {
                handleKeyUp(action);
            }
            if (typeMouse === 'down') {
                handleKeyDown(action);
            }
        },
        [handleKeyUp, handleKeyDown]
    );

    // Function to commit the position of the Tetris block to the board
    const commitPosition = useCallback(() => {
        if (!hasCollisions(board, droppingShape, droppingRow + 1, droppingColumn)) {
            setIsCommitting(false);
            setTickSpeed(TickSpeed.Normal);
            return;
        }
        const newBoard = structuredClone(board) as BoardShape;
        addShapeToBoard(
            newBoard,
            droppingBlock,
            droppingShape,
            droppingRow,
            droppingColumn
        );

        let numCleared = 0;
        for (let row = BOARD_HEIGHT - 1; row >= 0; row--) {
            if (newBoard[row].every((entry) => entry !== EmptyCell.Empty)) {
                numCleared++;
                newBoard.splice(row, 1);
            }
        }
        const newUpcomingBlocks = structuredClone(upcomingBlocks) as Block[];
        const newBlock = newUpcomingBlocks.pop() as Block;

        if (
            newBlock?.tetris &&
            hasCollisions(board, SHAPES[newBlock.tetris].shape, 0, 3)
        ) {
            setIsPlaying(false);
            setTickSpeed(null);
        } else {
            setTickSpeed(TickSpeed.Normal);
        }
        setUpcomingBlocks(newUpcomingBlocks);
        setScore((prevScore) => prevScore + getPoints(numCleared));
        dispatchBoardState({
            type: 'commit',
            newBoard: [
                ...initializeEmptyBoard(BOARD_HEIGHT - newBoard.length),
                ...newBoard,
            ],
            newBlock,
        });
        setIsCommitting(false);
    }, [
        board,
        dispatchBoardState,
        droppingBlock,
        droppingColumn,
        droppingRow,
        droppingShape,
        upcomingBlocks,
    ]);

    const gameTick = useCallback(() => {
        if (isCommitting) {
            commitPosition();
        } else if (
            hasCollisions(board, droppingShape, droppingRow + 1, droppingColumn)
        ) {
            // slide tetris faster
            setTickSpeed(TickSpeed.Normal);
            setIsCommitting(true);
        } else {
            // tetris go to the down
            dispatchBoardState({ type: 'drop' });
        }
    }, [
        board,
        commitPosition,
        dispatchBoardState,
        droppingColumn,
        droppingRow,
        droppingShape,
        isCommitting,
    ]);

    useInterval(() => {
        if (!isPlaying) {
            return;
        }
        if (numberOfTetrisCommit >= MAXTETRIS) {
            setTetrisFinish(true);
            setTickSpeed(null);
            return;
        } else {
            gameTick();
        }
    }, tickSpeed);

    useEffect(() => {
        if (!isPlaying) {
            return;
        }
    }, [isPlaying]);

    const renderedBoard = structuredClone(board) as BoardShape;
    if (isPlaying) {
        addShapeToBoard(
            renderedBoard,
            droppingBlock,
            droppingShape,
            droppingRow,
            droppingColumn
        );
    }

    return {
        board: renderedBoard,
        startTetrisGame,
        isPlaying,
        score,
        upcomingBlocks,
        handleClick,
        updateUpcomingBlocks,
        tetrisFinish,
        tetrisAutoCompletion,
    };
};

const POINTS_MAP = {
    0: 0,
    1: 100,
    2: 300,
    3: 500,
    4: 800,
};

const getPoints = (numCleared: number): number => {
    if (!Object.prototype.hasOwnProperty.call(POINTS_MAP, numCleared)) {
        throw new Error('Unexpected number of rows cleared');
    }
    return POINTS_MAP[numCleared as keyof typeof POINTS_MAP];
};

const addShapeToBoard = (
    board: BoardShape,
    droppingBlock: Block,
    droppingShape: BlockShape,
    droppingRow: number,
    droppingColumn: number
) => {
    droppingShape
        .filter((row) => row.some((isSet) => isSet))
        .forEach((row: boolean[], rowIndex: number) => {
            row.forEach((isSet: boolean, colIndex: number) => {
                if (isSet) {
                    board[droppingRow + rowIndex][droppingColumn + colIndex] =
                        droppingBlock as unknown as CellOptions;
                }
            });
        });
};
