import styles from '@/features/reusablecomponents/global/waitingPlayers/createRoom.module.scss';
import { emitSocketEvent } from '@/services/global/globalUtils';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CreateRoomProps {
    onRoomIdChosen: (roomName: string) => void;
    type: DeviceColorWithTvAndMediator;
}

const CreateRoom: React.FC<CreateRoomProps> = ({ onRoomIdChosen, type }) => {
    const [text, setText] = useState('');
    const { t } = useTranslation('global');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleConnectToSocketRoom = () => {
        onRoomIdChosen(text);
        emitSocketEvent('send_join_room', {
            device: type as DeviceColorWithTvAndMediator,
        });
    };

    return (
        <div className={styles.container}>
            <div>
                <input
                    type="text"
                    value={text}
                    placeholder={t('buttons.pin')}
                    onChange={handleInputChange}
                />
                <button onClick={handleConnectToSocketRoom}>
                    {t('buttons.validate')}
                </button>
            </div>
        </div>
    );
};

export default CreateRoom;
